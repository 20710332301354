import React from "react";
import { Col } from "react-bootstrap";
import { ContractLink, TokenLink } from "./Links";
import logo from "../images/Logo.png";
import "../stylesheets/Buy.css";

function Header({ address, token, tokenName, contractName }) {
  return (
    <header
      style={{ background: "transparent", border: "none" }}
      className="header"
    >
     
        <a
          target="_blank"
          href="https://openzeppelin.org"
          rel="noopener noreferrer"
        >
          <img
            className="logo hidden-xs hidden-sm"
            src={logo}
            alt="OpenZeppelin logo"
          />
        </a>
        <div className="contracts">
          <h3 style={{ fontSize: 21, color: "white" }} className="heading">
            {contractName} address:{" "}
            <ContractLink style={{ color: "#8F3DBB" }} address={address} />
          </h3>
          <span style={{ color: "white" }}>
            For{" "}
            <TokenLink
              style={{ color: "#8F3DBB" }}
              address={token}
              name={tokenName}
            />
          </span>
        </div>
     
    </header>
  );
}

export default Header;
