import React, { useState } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#221E40",
    color: "white",
    minWidth: "27rem",
    borderRadius: "16px",
    border: "0px"
  },
};

function TxModal({ txhash, status, error }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div>
      <Modal
        style={customStyles}
        isOpen={status !== "" && isOpen === true}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
      >
        <div className="">
          {status === "Pending" ? (
            <>
              <h5>Your Transaction is Pending, Please Wait...</h5>
              <div style={{textAlign: 'center'}}>
              <img
                className="spinner-img"
                src={require("../images/Cube.png").default}
                alt="spinner-img"
              />
              <br />
              <a target="#" href={"https://etherscan.io/tx/" + txhash}>
                Etherscan Link
                </a>
                </div>
            </>
          ) : null}
          {status === "Done" ? (
            <>
              <div className="img-div"></div>
               <img
                className="approve-img"
                src={require("../images/Approve.png").default}
                alt="spinner-img"
                />
                <div style={{textAlign: 'center'}}>
              <h5>Your Transaction Was Successful</h5>
              <a target="#" href={"https://etherscan.io/tx/" + txhash}>
                Etherscan Link
                </a>
                </div>
            </>
          ) : null}
          {status === "Error" ? (
            <>
               <div className="img-div"></div>
               <img
                className="failed-img"
                src={require("../images/Failed.png").default}
                alt="spinner-img"
              />
              <div style={{ textAlign: 'center' }}>
                
              <h5>There was some error with the transaction</h5>
                <p>Error - {error}</p>
                </div>
            </>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}

export default TxModal;
