import React, { Component } from "react";
import { Row, Table, Col, Container } from "react-bootstrap";
import moment from "moment";

import { getTokenVesting } from "../contracts";
import { displayAmount } from "../utils";
import Network from "../network";

import { ContractLink } from "./Links";
import Emoji from "./Emoji";
import TxModal from "./TxModal";
import "../stylesheets/Buy.css";

class VestingDetails extends Component {
  constructor() {
    super();
    this.state = { canRevoke: false };
    this.onRelease = this.onRelease.bind(this);
  }

  render() {
    const {
      start,
      cliff,
      end,
      total,
      released,
      vested,
      revocable,
      beneficiary,
    } = this.props.details;
    const releasable = vested ? vested - released : 0;
    const cliffPast = cliff < new Date() / 1000 ? true : false;

    return (
      <div>
        <Container>
          <Row style={{ marginTop: "3rem" }}>
            <Col>
              <div className="card-container" style={{}}>
                <div className="card-header">
                  <h2
                    className="heading"
                    style={{ textAlign: "left", marginLeft: "0.6rem" }}
                  >
                    Thank you for investing in Demodyfi!
                  </h2>
                </div>
                <h4
                  className="sub-heading"
                  style={{
                    fontSize: 22,
                    textAlign: "left",
                    marginLeft: "2rem",
                  }}
                >
                  We are glad to have you here, now you can view and claim your
                  tokens
                </h4>
                <h4
                  className="heading"
                  style={{
                    textAlign: "left",
                    fontSize: 18,
                    marginLeft: "2rem",
                  }}
                >
                  Beneficiary Address - <ContractLink address={beneficiary} />
                </h4>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "3rem" }}>
            <Col>
              <div className="card-container">
                <div className="card-header">
                  <h2
                    className="heading"
                    style={{
                      textAlign: "left",
                      marginLeft: "0.6rem",
                    }}
                  >
                    Stake DMOD to earn more DMOD
                  </h2>
                </div>
                <h4
                  className="sub-heading"
                  style={{
                    fontSize: 18,
                    textAlign: "left",
                    marginLeft: "2rem",
                  }}
                >
                  You can stake you DMOD tokens at our pools to earn more DMOD.
                  You can either stake your DMOD tokens or provide liquidity for
                  our token on Uniswap and Pancakswap.
                </h4>
                <button
                  style={{
                    marginLeft: "2rem",
                    width: "18rem",
                    marginTop: "1rem",
                  }}
                  className="StakePublicButton"
                  onClick={() => {
                    window.location.href = "https://staking.demodyfi.com";
                  }}
                >
                  Staking Available Now
                </button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "3rem" }}>
            <Col md={7}>
              <div className="card-container" style={{}}>
                <div className="card-header">
                  <h4 className="heading">Vesting details</h4>
                </div>
                <Row>
                  <Col md={7}>
                    <Table
                      responsive
                      borderless
                      style={{
                        marginLeft: "2rem",
                        width: "20rem",
                      }}
                    >
                      <tbody
                        style={{ textAlign: "left", lineHeight: "10px" }}
                        className="heading-data"
                      >
                        {/* <TableRow title="Beneficiary">
              <ContractLink address={beneficiary} />
            </TableRow> */}

                        <TableRow
                          style={{ width: "2rem" }}
                          title="Total vesting"
                        >
                          {this.formatTokens(total)}
                        </TableRow>

                        <TableRow title="Already vested">
                          {this.formatTokens(vested)}
                        </TableRow>

                        <TableRow title="Already released">
                          {this.formatTokens(released)}
                        </TableRow>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={5}>
                    <div>
                      {cliffPast ? (
                        <h2 className="heading" style={{ fontSize: "20px" }}>
                          <Releasable
                            releasable={releasable}
                            onRelease={() => this.onRelease()}
                          >
                            {this.formatTokens(releasable)}
                          </Releasable>
                        </h2>
                      ) : (
                        <h2 className="heading" style={{ fontSize: "20px" }}>
                          <Releasable
                            releasable={releasable}
                            onRelease={() => this.onRelease()}
                          >
                            0 DMOD
                          </Releasable>
                        </h2>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className="card-container" style={{}}>
                <div className="card-header">
                  <h4 className="heading">Vesting Period</h4>
                </div>
                <Table responsive borderless style={{}}>
                  <tbody
                    style={{ textAlign: "left", lineHeight: "10px" }}
                    className="heading-data"
                  >
                    <TableRow
                      style={{ padding: 0, width: "2rem" }}
                      title="Start date"
                    >
                      {this.formatDate(start)}
                    </TableRow>

                    <TableRow title="Cliff">{this.formatDate(cliff)}</TableRow>

                    <TableRow title="End date">{this.formatDate(end)}</TableRow>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  formatDate(date) {
    if (!date) return;
    const milliseconds = date * 1000;
    return moment(milliseconds).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  formatTokens(amount) {
    if (amount == null) return;
    const { decimals, symbol } = this.props.details;
    const display = displayAmount(amount, decimals);

    return `${display} ${symbol}`;
  }

  startLoader() {
    this.props.setLoader(true);
  }

  stopLoader() {
    this.props.setLoader(false);
  }

  async getTokenVesting() {
    return getTokenVesting(this.props.address);
  }

  async onRelease() {
    try {
      const { owner, revoked } = this.props.details;
      await Network.connectToWallet();
      const accounts = await Network.getAccounts();

      const isOwner = accounts[0]
        ? owner === accounts[0].toLowerCase()
        : undefined;

      this.setState({ canRevoke: isOwner && !revoked, accounts });

      const { token } = this.props;
      const tokenVesting = await this.getTokenVesting();

      this.startLoader();
      tokenVesting
        .release(token, { from: accounts[0] })
        .on("transactionHash", (hash) => {
          this.props.setTxData(hash, "Pending", "");
        })
        .on("confirmation", (_, receipt) => {
          console.log(receipt);
        })
        .on("receipt", (receipt) => {
          this.props.setTxData(receipt.transactionHash, "Done", "");
        })
        .on("error", (error) => {
          console.log(
            "%cMyProject%cline:140%cerror",
            "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
            "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
            "color:#fff;background:rgb(248, 214, 110);padding:3px;border-radius:2px",
            error.message
          );
          this.props.setTxData("txhash", "Error", error.message);
        });
      // const tx = await tokenVesting.beneficiary({ from: accounts[0] });
      this.props.getData();
    } catch (e) {
      console.log(e);
      this.stopLoader();
      this.props.setTxData("txhash", "Error", e);
    }
  }

  async onRevoke() {
    const { owner, revoked } = this.props.details;
    await Network.connectToWallet();
    const accounts = await Network.getAccounts();

    const isOwner = accounts[0]
      ? owner === accounts[0].toLowerCase()
      : undefined;

    this.setState({ canRevoke: isOwner && !revoked, accounts });

    const { token } = this.props;
    const tokenVesting = await this.getTokenVesting();

    try {
      // this.startLoader();
      await tokenVesting.revoke(token, { from: accounts[0] });
      this.props.getData();
    } catch (e) {
      this.stopLoader();
    }
  }
}

function TableRow({ title, children }) {
  return (
    <tr>
      <th style={{ minWidth: "auto" }}>{title}</th>
      <td>{children}</td>
    </tr>
  );
}

function Revocable({ revocable, onRevoke, canRevoke }) {
  if (!revocable) return <Emoji e="❌" />;

  return (
    <span>
      <Emoji e="✅" />
      {canRevoke ? (
        <a className="action" onClick={onRevoke}>
          Revoke
        </a>
      ) : null}
    </span>
  );
}

function Releasable({ releasable, onRelease, children }) {
  return (
    <span>
      {children}
      {releasable > 0 ? (
        <div style={{ marginRight: "1rem" }}>
          <button className="PublicButton" onClick={onRelease}>
            Release
          </button>
        </div>
      ) : null}
    </span>
  );
}

export default VestingDetails;
