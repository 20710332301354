import React, { Component } from "react";

import { getDmodCrowdsale, getAggregatorV3 } from "../contracts";

import Network from "../network";
import Header from "./Header";

import Spinner from "./Spinner";
import Footer from "./Footer";
import "../stylesheets/Buy.css";
import logo from "../images/Logo.png";

export default class PublicVesting extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.updateVestingAddress = this.updateVestingAddress.bind(this);
    this.goToVesting = this.goToVesting.bind(this);
  }

  async componentDidMount() {}

  async updateVestingAddress(e) {
    const inputValue = e.target.value;
    this.setState({
      userAddress: inputValue,
    });
  }

  async goToVesting() {
    this.props.history.push(
      `/public/${this.state.userAddress}/0x5f6c5c2fb289db2228d159c69621215e354218d7`
    );
  }

  render() {
    return (
      <div className="BuyComponent">
        {this.state.loading ? <Spinner /> : null}

        <Header
          address={"0x5f6c5c2fb289db2228d159c69621215e354218d7"}
          token={"0x5f6c5c2fb289db2228d159c69621215e354218d7"}
          tokenName={"Demodyfi Token"}
          contractName={"DMOD"}
        />
        <div style={{height:'55vh'}}>
        <div className="card-container">
          <div>
            <img src={logo} alt="domodyfi logo" className="logo-image" />
          </div>
          <h2 className="heading">Public Vesting Info</h2>
          <h6 className="sub-heading">
            Enter the address from which you bought tokens
          </h6>
          <input
            placeholder="Enter Your Address"
            type="text"
            onChange={this.updateVestingAddress}
            className="inputBuy"
          />
          <div style={{ textAlign: "center" }}>
            <button
              style={{ position: "inherit", marginTop: "2rem" }}
              className="PrivateButton"
              onClick={this.goToVesting}
            >
              Get Vesting Info
            </button>
          </div>
          </div>
          </div>
        <Footer />
      </div>
    );
  }
}
