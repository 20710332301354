import React from "react";
import {
  faGithub,
  faTwitter,
  faTelegram,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../stylesheets/Footer.css";
import argo from "../images/argo.png";
import zepplin from "../images/zepplin.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-div">
        <div className="footer-left">
          © 2021 Demodyfi •  <img className="zepplin" src={zepplin} alt="icon" /> Made with{"  "}
          <span style={{ color: "#ff9a36",  marginLeft: "0.4rem"  }}>Open Zeppelin </span>
        </div>
        <div className="argo-div">
        <img className="argo" src={argo} alt="icon" />
          Deployed by{"  "}
          <span style={{ color: "#19D2FF" }}>Argo</span>
       
        </div>
      </div>
      <div className="footer-right">
        <div
          className="footer-icon"
          onClick={(e) =>
            window.open(
              "https://github.com/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faGithub} />
        </div>
        <div
          className="footer-icon"
          onClick={(e) =>
            window.open(
              "https://twitter.com/DemodyFi",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faTwitter} />
        </div>
        <div
          className="footer-icon"
          onClick={(e) =>
            window.open(
              "https://t.me/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faTelegram} />
        </div>
        <div
          className="footer-icon"
          onClick={(e) =>
            window.open(
              "https://demodyfi.medium.com/",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faMedium} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
