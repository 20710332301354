import React, { Component } from "react";

import { getDmodCrowdsale, getAggregatorV3 } from "../contracts";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Network from "../network";
import Header from "./Header";

import Spinner from "./Spinner";
import Footer from "./Footer";
import "../stylesheets/Buy.css";
import { Button, Card } from "react-bootstrap";
import logo from "../images/Logo.png";
import vector from "../images/Vector.png";
import learn from "../images/learn.png";
import union from "../images/Union.png";

export default class Vesting extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goToInvestors = this.goToInvestors.bind(this);
    this.goToPublic = this.goToPublic.bind(this);
  }

  async goToInvestors() {
    this.props.history.push("/investors");
  }

  async goToPublic() {
    this.props.history.push("/public");
  }

  render() {
    // const Item = Accordion.Item;
    // const Item1 = Accordion.Header;
    // const Item2 = Accordion.Body;
    return (
      <div className="BuyComponent">
         {this.state.loading ? <Spinner /> : null}
        <div>
         

          <Header
            address={"0x5f6c5c2fb289db2228d159c69621215e354218d7"}
            token={"0x5f6c5c2fb289db2228d159c69621215e354218d7"}
            tokenName={"Demodyfi Token"}
            contractName={"DMOD"}
          />
          <Container className="card-container-main">
            <Row>
              <Col md={12}>
                <div>
                  <img src={logo} alt="domodyfi logo" className="logo-image" />
                </div>
                <h2 className="card-heading">Demodyfi Vesting Info</h2>
                <h6 className="sub-heading">
                  In what round did you invest in demodyfi ?
                </h6>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "2rem", flexWrap: "nowrap", marginLeft: 0 }}
            >
              <Col md={6} style={{ paddingLeft: 0 }}>
                <button className="PrivateButton" onClick={this.goToInvestors}>
                  Seed/Private
                </button>
              </Col>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <button className="PublicButton" onClick={this.goToPublic}>
                  Public
                </button>
              </Col>
            </Row>
          </Container>
          <Container>
            <div className="vesting-info-table">
              <h4 className="card-heading">Investment Rounds Schedules</h4>
              <br />
              <Table striped bordered condensed>
                <tbody>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      className="table-heading"
                    >
                      Investment Round
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="table-heading"
                    >
                      Vesting Schedule
                    </th>
                  </tr>
                  <tr>
                    <td className="heading-data">Seed</td>
                    <td className="heading-info">
                      5% at TGE, rest vests over 12 months
                    </td>
                  </tr>
                  <tr>
                    <td className="heading-data">Private</td>
                    <td className="heading-info">
                      10% at TGE, rest vests over 12 months
                    </td>
                  </tr>
                  <tr>
                    <td className="heading-data">Public</td>
                    <td className="heading-info">
                      33% at TGE, rest vests over 33% monthly
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <br />
            </div>
          </Container>
        </div>
        <div className="Faq">
          <div style={{ paddingTop: "3rem" }}>
            <Container>
              <Row style={{marginLeft: "-50px", marginRight: "0px"}}>
                <Col>
                  <img className="vector-img" src={vector} alt="vector" />
                </Col>
                <Col>
                  {/* <h4 style={{ fontSize: 25 }} className="heading">FAQs</h4> */}
                  <img className="union-img" src={union} alt="union" />
                  <h5 className="small-heading" style={{ marginTop: "-2rem" }}>
                    For any queries please visit our FAQs page here
                  </h5>

                  <a
                    target="#"
                    href="https://www.notion.so/Vesting-and-Distribution-FAQs-2f5afcb1d65e4118abf1d9b384d04595"
                  >
                    <img className="learn-img" src={learn} alt="vector" />
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

function TableRow({ title, children }) {
  return (
    <tr>
      <th>{title}</th>
      <td>{children}</td>
    </tr>
  );
}
