import contract from "@truffle/contract";
import Network from "./network";

export async function getTokenVesting(address) {
  const TokenVesting = contract(require("./TokenVesting.json"));
  const provider = await Network.provider();
  TokenVesting.setProvider(provider);
  return TokenVesting.at(address);
}

export async function getPublicTokenVesting1() {
  const TokenVesting = contract({
    abi: require("./airdrop.json"),
  });
  const provider = await Network.provider();
  TokenVesting.setProvider(provider);
  return TokenVesting.at("0x745a21B68863aF2Af642538D51EaBc201e6AfecD");
}

export async function getPublicTokenVesting2() {
  const TokenVesting = contract({
    abi: require("./airdrop.json"),
  });
  const provider = await Network.provider();
  TokenVesting.setProvider(provider);
  return TokenVesting.at("0x0eDdE775154F4942F68A512e7c14E083F13D35FB");
}

export async function getSimpleToken(address) {
  const SimpleToken = contract(require("./SimpleToken.json"));
  const provider = await Network.provider();
  SimpleToken.setProvider(provider);
  return SimpleToken.at(address);
}

export async function getDmodCrowdsale(address) {
  const DMODCrowdsale = contract(require("./DMODCrowdsale.json"));
  const provider = await Network.provider();
  DMODCrowdsale.setProvider(provider);
  return DMODCrowdsale.at(address);
}

export async function getAggregatorV3(address) {
  const AggregatorV3Interface = contract(
    require("./AggregatorV3Interface.json")
  );
  const provider = await Network.provider();
  AggregatorV3Interface.setProvider(provider);
  return AggregatorV3Interface.at(address);
}
