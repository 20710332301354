import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";

import {
  getTokenVesting,
  getSimpleToken,
  getPublicTokenVesting1,
  getPublicTokenVesting2,
} from "../contracts";

import Header from "./Header";
import VestingDetails from "./VestingDetails";
import VestingSchedule from "./VestingSchedule";
import Spinner from "./Spinner";
import "../stylesheets/Buy.css";
import "../stylesheets/TokenVestingApp.css";
import Network from "../network";
import Footer from "./Footer";
import TxModal from "./TxModal";
import PublicDetails from "./PublicDetails";
import PublicSchedule from "./PublicSchedule";
import { userData } from "../data";
import { userData1 } from "../data1";

class PublicTokenVestingApp extends Component {
  constructor() {
    super();
    this.state = {
      name: "Token",
      loading: true,
      txhash: "",
      txStatus: "",
      error: "",
    };

    this.setTxData = this.setTxData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  setTxData(hash, status, error) {
    this.setState({
      txhash: hash,
      txStatus: status,
      error: error,
    });
  }

  render() {
    const { address, token } = this.props;
    return (
      <div className="TokenVestingApp">
        {this.state.loading ? <Spinner /> : null}
        {this.state.txhash !== "" ? (
          <TxModal
            txhash={this.state.txhash}
            status={this.state.txStatus}
            error={this.state.error}
          />
        ) : null}
        <Header
          address={address}
          token={token}
          tokenName={this.state.name}
          contractName={"User"}
        />
        <Container>
          <PublicDetails
            address={address}
            token={token}
            details={this.state}
            getData={() => this.getData()}
            setLoader={(x) => this.setLoader(x)}
            setTxData={this.setTxData}
          />
          <Row>
            <Col>
              <div
                class="card-container"
                style={{ marginTop: "3rem", padding: "1rem" }}
              >
                <PublicSchedule details={this.state} />
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }

  setLoader(loading) {
    this.setState({ loading });
  }

  async getData() {
    try {
      const { address, token } = this.props;

      const tokenVesting1 = await getPublicTokenVesting1();
      const tokenVesting2 = await getPublicTokenVesting2();

      const tokenContract = await getSimpleToken(token);

      const vested = await tokenVesting1.whiteListedAddresses(address);
      const vested1 = await tokenVesting2.whiteListedAddresses(address);

      const start = 1623337200;
      const cliff = 1625929200;
      const end = 1628607600;

      const data = userData.find((d) => d.address === address.toLowerCase());
      const data1 = userData1.find((d) => d.address === address.toLowerCase());

      const total = data.amount;

      this.setState({
        start,
        end,
        cliff,
        total,
        vested,
        beneficiary: address,
        decimals: await tokenContract.decimals(),
        name: await tokenContract.name(),
        symbol: await tokenContract.symbol(),
        loading: false,
        vested1,
      });
    } catch (error) {
      console.log(error);
      alert("Data Not Found");
      window.location.href = "/";
    }
  }
}

export default PublicTokenVestingApp;
