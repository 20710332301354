import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import { getPublicTokenVesting1, getPublicTokenVesting2, getTokenVesting } from "../contracts";
import { displayAmount } from "../utils";
import Network from "../network";
import { ContractLink } from "./Links";
import Emoji from "./Emoji";
import TxModal from "./TxModal";
import "../stylesheets/Buy.css";

class PublicDetails extends Component {
  constructor() {
    super();
    this.state = { canRevoke: false, invalidAddress: false };
    this.onRelease = this.onRelease.bind(this);
  }

  render() {
    const {
      start,
      end,
      cliff,
      total,
      vested,
      beneficiary,
      decimals,
      name,
      symbol,
      loading,
      vested1,
    } = this.props.details;
    console.log(
      "%cMyProject%cline:35%cvested",
      "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
      "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
      "color:#fff;background:rgb(38, 157, 128);padding:3px;border-radius:2px",
      vested
    );
    console.log(
      "%cMyProject%cline:36%cvested1",
      "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
      "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
      "color:#fff;background:rgb(252, 157, 154);padding:3px;border-radius:2px",
      vested1
    );
    const cliffPast = true;
    return (
      <div>
        <Container>
          <Row style={{ marginTop: "3rem" }}>
            <Col>
              <div className="card-container" style={{}}>
              <div className="card-header">
                <h2
                  className="heading"
                  style={{ textAlign: "left", marginLeft: "0.6rem" }}
                >
                  Thank you for investing in Demodyfi!
                </h2>
                </div>
                <h4
                  className="sub-heading"
                  style={{
                    fontSize: 22,
                    textAlign: "left",
                    marginLeft: "2rem",
                  }}
                >
                  We are glad to have you here, now you can view and claim your
                  tokens
                </h4>
                <h4
                  className="heading"
                  style={{
                    textAlign: "left",
                    fontSize: 18,
                    marginLeft: "2rem",
                  }}
                >
                  Beneficiary Address - <ContractLink address={beneficiary} />
                </h4>
                <h6
                  className="sub-heading"
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    marginLeft: "2rem",
                    color: "#19D2FF",
                  }}
                >
                  DMOD bought from BSCPAD IDO sale can only be claimed from
                  BSCPAD dashboard but the vesting details can be viewed here
                </h6>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "3rem" }}>
            <Col>
              <div className="card-container">
                <div className="card-header">
                <h2
                  className="heading"
                  style={{
                    textAlign: "left",
                    marginLeft: "0.6rem",
                  }}
                >
                  Stake DMOD to earn more DMOD
                </h2> 
                </div>
                <h4
                  className="sub-heading"
                  style={{
                    fontSize: 18,
                    textAlign: "left",
                    marginLeft: "2rem",
                  }}
                >
                  You can stake you DMOD tokens at our pools to earn more DMOD.
                  You can either stake your DMOD tokens or provide liquidity for
                  our token on Uniswap and Pancakswap, and stake those LP tokens
                  for rewards.
                </h4>
                <button
                  style={{}}
                  className="StakePublicButton"
                  onClick={() => {
                    window.location.href = "https://staking.demodyfi.com";
                  }}
                >
                  Staking Available Now
                </button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "3rem" }}>
            <Col md={7}>
              <div className="card-container" style={{}}>
              <div className="card-header">
                <h4 className="heading">Vesting details</h4></div>
                <Row>
                  <Col md={7}>
                    <Table
                      responsive
                      borderless
                      style={{
                        marginLeft: "2rem",
                        width: "20rem",
                      }}
                    >
                      <tbody
                        style={{ textAlign: "left", lineHeight: "10px" }}
                        className="heading-data"
                      >
                        {/* <TableRow title="Beneficiary">
              <ContractLink address={beneficiary} />
            </TableRow> */}

                        {total ? (
                          <TableRow title="Total vesting">
                            {total.toFixed(2)} DMOD
                          </TableRow>
                        ) : null}
                        {total ? (
                          <TableRow title="Already vested">
                            {total.toFixed(2)} DMOD
                          </TableRow>
                        ) : null}

                        <TableRow title="Already released">
                          {(total * 0.66).toFixed(2)} DMOD
                        </TableRow>
                      </tbody>
                    </Table>
                  </Col>
                  {cliffPast && vested ? (
                    <Col md={5}>
                      <div>
                        <h2 className="heading" style={{ fontSize: "20px" }}>
                          <Releasable
                            releasable={vested}
                            releasable1={vested1}
                            onRelease={() => this.onRelease()}
                            onRelease1={() => this.onRelease1()}
                            invalidAddress={this.state.invalidAddress}
                          >
                            {this.formatTokens(vested)}
                          </Releasable>
                        </h2>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className="card-container" style={{}}>
              <div className="card-header">
                <h4 className="heading">Vesting Period</h4>
</div>
                <Table responsive borderless style={{ width: "28rem" }}>
                  <tbody
                    style={{ textAlign: "left", lineHeight: "10px" }}
                    className="heading-data"
                  >
                    <TableRow
                      style={{ padding: 0, width: "2rem" }}
                      title="Start date"
                    >
                      {this.formatDate(start)}
                    </TableRow>

                    <TableRow title="Release 1">
                      {this.formatDate(cliff)}
                    </TableRow>

                    <TableRow title="Release 2">
                      {this.formatDate(end)}
                    </TableRow>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  formatDate(date) {
    if (!date) return;
    const milliseconds = date * 1000;
    return moment(milliseconds).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  formatTokens(amount) {
    if (amount == null) return;
    const { decimals, symbol } = this.props.details;
    const display = displayAmount(amount, decimals);

    return `${display} ${symbol}`;
  }

  startLoader() {
    this.props.setLoader(true);
  }

  stopLoader() {
    this.props.setLoader(false);
  }

  async getTokenVesting() {
    return getTokenVesting(this.props.address);
  }

  async onRelease() {
    try {
      await Network.connectToWallet();
      const accounts = await Network.getAccounts();
      console.log("accounts", accounts);
      if (
        accounts[0].toLowerCase() !==
        this.props.details.beneficiary.toLowerCase()
      ) {
        this.setState({
          invalidAddress: true,
        });
        return;
      }

      const { token } = this.props;
      const tokenVesting = await getPublicTokenVesting1(this.props.address);

      try {
        this.startLoader();
        tokenVesting
          .claimDistribution({ from: accounts[0] })
          .on("transactionHash", (hash) => {
            this.props.setTxData(hash, "Pending", "");
          })
          .on("confirmation", (_, receipt) => {
            console.log(receipt);
          })
          .on("receipt", (receipt) => {
            this.props.setTxData(receipt.transactionHash, "Done", "");
          })
          .on("error", (error) => {
            console.log(
              "%cMyProject%cline:140%cerror",
              "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
              "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
              "color:#fff;background:rgb(248, 214, 110);padding:3px;border-radius:2px",
              error.message
            );
            this.props.setTxData("txhash", "Error", error.message);
          });
      } catch (e) {
        this.props.setTxData("txhash", "Error", e.message);
      }
      // const tx = await tokenVesting.beneficiary({ from: accounts[0] });
      this.props.getData();
    } catch (e) {
      alert(e.message);
      this.stopLoader();
    }
  }

  async onRelease1() {
    try {
      await Network.connectToWallet();
      const accounts = await Network.getAccounts();
      console.log("accounts", accounts);
      if (
        accounts[0].toLowerCase() !==
        this.props.details.beneficiary.toLowerCase()
      ) {
        this.setState({
          invalidAddress: true,
        });
        return;
      }

      const { token } = this.props;
      const tokenVesting = await getPublicTokenVesting2(this.props.address);

      try {
        this.startLoader();
        tokenVesting
          .claimDistribution({ from: accounts[0] })
          .on("transactionHash", (hash) => {
            this.props.setTxData(hash, "Pending", "");
          })
          .on("confirmation", (_, receipt) => {
            console.log(receipt);
          })
          .on("receipt", (receipt) => {
            this.props.setTxData(receipt.transactionHash, "Done", "");
          })
          .on("error", (error) => {
            console.log(
              "%cMyProject%cline:140%cerror",
              "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
              "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
              "color:#fff;background:rgb(248, 214, 110);padding:3px;border-radius:2px",
              error.message
            );
            this.props.setTxData("txhash", "Error", error.message);
          });
      } catch (e) {
        this.props.setTxData("txhash", "Error", e.message);
      }
      // const tx = await tokenVesting.beneficiary({ from: accounts[0] });
      this.props.getData();
    } catch (e) {
      alert(e.message);
      this.stopLoader();
    }
  }
}

function TableRow({ title, children }) {
  return (
    <tr>
      <th>{title}</th>
      <td>{children}</td>
    </tr>
  );
}

function Revocable({ revocable, onRevoke, canRevoke }) {
  if (!revocable) return <Emoji e="❌" />;

  return (
    <span>
      <Emoji e="✅" />
      {canRevoke ? (
        <a className="action" onClick={onRevoke}>
          Revoke
        </a>
      ) : null}
    </span>
  );
}

function Releasable({
  releasable,
  releasable1,
  onRelease,
  onRelease1,
  invalidAddress,
  children,
}) {
  const formatTokens = (amount) => {
    if (amount == null) return;
    const symbol = "DMOD";
    const display = displayAmount(amount, 18);

    return `${display} ${symbol}`;
  };

  return (
    <span>
      {releasable > 0 || releasable1 > 0 ? (
        <div style={{marginRight: '1rem'}}>
          {invalidAddress ? (
            <button className="PublicButton">Invalid Address Connected</button>
          ) : (
            <>
              <button className="PublicButton" onClick={onRelease}>
                {formatTokens(releasable)}
              </button>
              <button
                style={{ marginTop: "0.5rem" }}
                className="PublicButton1"
                onClick={onRelease1}
              >
                {formatTokens(releasable1)}
              </button>
            </>
          )}
        </div>
      ) : null}
    </span>
  );
}

export default PublicDetails;
